
import $app from '@/plugins/modules'
import { System } from '@/modules/system'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ICuringPlan, IPatient, ISurfaceAction, ITooth, IToothDamage, IToothMarker } from '../types';
import { IManipulation, IManipulationType } from '@/modules/clinic/types';
import { Errors } from '@/common/types';

@Component
export default class CustomerTeesTab extends Vue {
  @Prop({ default: () => { } })
  readonly patient: IPatient | undefined;

  @Prop()
  readonly readonly: any;

  system = $app.module(System);

  toothVisible = false;
  toothProcessing = false;
  toothSelected: ITooth | null = null;
  toothTab = 0;

  newManipulationsVisible = false;
  newManipulationsProcessing = false;
  newManipulations: Array<IManipulationType> = [];
  currentPlan: number | null = null;

  markerSelected: number | null = null;
  markerCure = '';
  markerDamage = '';

  @Watch('newManipulationsVisible')
  async onShowNewManipulations() {
    if (this.newManipulationsVisible) {
      this.currentPlan = null;
      this.plans.forEach(p => {
        if (this.currentPlan === null || p.state === 'A') {
          this.currentPlan = p.id;
        }
      });
    }
  }

  get isReadOnly() {
    return this.readonly === '' || this.readonly === true
  }

  get teethUp(): Array<ITooth> {
    return this.patient && this.patient.teeth
      ? this.patient.teeth.filter(t => t.position === 'U')
      : [];
  }

  get teethDown(): Array<ITooth> {
    return this.patient && this.patient.teeth
      ? this.patient.teeth.filter(t => t.position === 'L')
      : [];
  }

  get plans(): Array<ICuringPlan> {
    return this.patient && this.patient.plans ? this.patient.plans.filter(p => p.state !== 'S') : []
  }

  getToothNewDamages(tooth: ITooth): Array<IToothDamage> {
    return tooth && tooth.damages ? tooth.damages.filter(i => i.appended) : [];
  }

  markDamage(cure: string, useDamage: boolean) {
    this.markerCure = cure;
    this.markerDamage = useDamage && this.system.$store.getCurrentUser.toolbarDamage
      ? this.system.$store.getCurrentUser.toolbarDamage
      : '';
  }

  cancelMarker() {
    this.markerSelected = null;
  }

  async sendMarker(action: ISurfaceAction) {
    const marker: IToothMarker = {
      damageCode: this.markerDamage,
      toothNumber: action.tooth.number,
      patientId: this.patient!.patientId || 0,
      cure: this.markerCure,
      surface: action.surface.type
    }

    action.surface.processing = true;
    try {
      await $app.post('/api/patients/' + marker.patientId + '/marks', marker);
      action.surface.damage = this.markerDamage !== '';
      action.surface.cure = this.markerCure;
    } catch (err) {
      $app.pushError(err);
    }
    action.surface.processing = false;
  }

  open(tooth: ITooth, tab: number) {
    if (!this.isReadOnly) {
      if (this.markerSelected === null || this.markerSelected === undefined) {
        this.toothSelected = tooth;
        this.toothTab = tab;
        this.toothVisible = true;
      }
    }
  }

  replaceTooth(tooth: ITooth) {
    const teeth = this.patient!.teeth;
    const index = teeth.findIndex(i => i.number === tooth.number);

    if (index !== -1) {
      teeth.splice(index, 1, tooth);
    }
  }

  async update(tooth: ITooth) {
    try {
      this.replaceTooth(tooth);
    } catch (err) {
      $app.pushError(err);
    }
  }

  selectPlan(value: number) {
    this.currentPlan = value;
  }

  async removeNewManipulation(index: number) {
    this.newManipulations.splice(index, 1);
  }

  async createNewPlan() {
    this.newManipulationsProcessing = true;
    try {
      let plan: ICuringPlan = {
        id: null,
        name: '',
        state: 'P',
        patientId: this.patient!.patientId || 0,
        author: null,
        details: '',
        created: null,
        archived: null,
        manipulations: [],
        processing: false
      };
      plan = await $app.post('/api/patients/' + this.patient!.patientId + '/curings', plan);

      this.patient!.plans.push(plan);
      this.currentPlan = plan.id;
    } catch (err) {
      $app.pushError(err);
    }
    this.newManipulationsProcessing = false;
  }

  async processNewManipulations() {
    if (this.currentPlan && this.toothSelected) {
      const plan = this.plans.find(p => p.id === this.currentPlan);
      if (plan) {
        this.newManipulationsProcessing = true;
        try {
          for (let i = 0; i < this.newManipulations.length; i++) {
            const manipulationType = this.newManipulations[i];
            if (!manipulationType.processed) {
              manipulationType.processing = true;
              try {
                let manipulation: IManipulation = {
                  id: null,
                  typeId: null,
                  code: manipulationType.code,
                  name: null,
                  state: 'P',
                  visit: null,
                  curing: this.currentPlan,
                  tooth: this.toothSelected.number,
                  price: null,
                  done: null,
                  doctor: null,
                  surfaces: null,
                  surfacesCount: null,
                  editable: true,
                  processed: false,
                  processing: false
                }
                manipulation = await $app.post('/api/patients/' + this.patient!.patientId + '/curings/' + this.currentPlan + '/manipulations', manipulation)
                plan.manipulations.push(manipulation);
                manipulationType.processed = true;
              } catch (err) {
                $app.pushError(err);
              }
              manipulationType.processing = false;
            }
          }
          this.newManipulationsVisible = false;
        } catch (err) {
          $app.pushError(err);
        }
      } else {
        $app.pushError(Errors().NO_CURING_PLAN);
      }
      this.newManipulationsProcessing = false;
    } else {
      $app.pushError(Errors().NO_CURING_PLAN);
    }
  }

  async store(tooth: ITooth) {
    this.toothProcessing = true;
    try {
      this.replaceTooth(await $app.post('/api/patients/' + this.patient!.patientId + '/teeth', tooth));

      const newDamages = this.getToothNewDamages(tooth)
      if (newDamages.length > 0) {
        const index: any = {};

        const newManipulations: Array<IManipulationType> = []
        for (let i = 0; i < newDamages.length; i++) {
          const id = newDamages[i].damageId;
          if (id) {
            const manipulations: Array<IManipulationType> = await $app.get('/api/clinics/damages/' + id + '/manipulations');
            manipulations.forEach(m => {
              if (!index[m.code]) {
                index[m.code] = true;
                newManipulations.push(m);
              }
            });
          }
        }

        this.newManipulations = newManipulations;
        this.newManipulationsVisible = true;
      }
      this.toothVisible = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.toothProcessing = false;
  }
}
